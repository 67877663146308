import { Storage } from "../Storage";
import getHeaders from "./getHeaders";
import handleResponse from "./handleResponse";

const handleError = (error: any) => {
  throw new Error(
    "Could not connect to MOXSEA services. Please check your Internet connection and try again."
  );
};

/**
 * Performs a GET request to the specified URL with the common API headers and handles the response.
 *
 * @param url - The URL to send the GET request to.
 * @returns A Promise that resolves to the parsed JSON data from the response.
 * @template T - The type of the data expected in the response body.
 */
const get = async <T = any>(url: any) =>
  fetch(url, {
    headers: await getHeaders(),
  })
    .catch(handleError)
    .then((response) => handleResponse<T>(response, "GET", url));

/**
 * Performs a POST request to the specified URL with the provided data and the common API headers,
 * and handles the response.
 *
 * @param url - The URL to send the POST request to.
 * @param data - The data to be included in the request body.
 * @returns A Promise that resolves to the parsed JSON data from the response.
 * @template T - The type of the data expected in the response body.
 */
const post = async <T = any>(url: string, data: any) =>
  fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: await getHeaders(),
  })
    .catch(handleError)
    .then((response) => handleResponse<T>(response, "POST", url, data));

/**
 * Performs a POST request to the specified URL with the provided data and the common API headers,
 * and handles the response.
 *
 * @param url - The URL to send the POST request to.
 * @param data - The data to be included in the request body.
 * @returns A Promise that resolves to the parsed JSON data from the response.
 * @template T - The type of the data expected in the response body.
 */
const patch = async <T = any>(url: string, data: any) =>
  fetch(url, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: await getHeaders(),
  })
    .catch(handleError)
    .then((response) => handleResponse<T>(response, "PATCH", url, data));

/**
 * Performs a PUT request to the specified URL with the provided data and the common API headers,
 * and handles the response.
 *
 * @param url - The URL to send the PUT request to.
 * @param data - The data to be included in the request body.
 * @returns A Promise that resolves to the parsed JSON data from the response.
 */
const put = async <T>(url: string, data: any) =>
  fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: await getHeaders(),
  })
    .catch(handleError)
    .then((response) => handleResponse<T>(response, "PUT", url, data));




/**
* Performs a DELETE request to the specified URL with the common API headers,
* and handles the response.
*
* @param url - The URL to send the DELETE request to.
* @returns A Promise that resolves to the parsed JSON data from the response.
*/
const destroy = async <T>(url: string, data?: any) =>
  fetch(url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: await getHeaders(),
  })
    .catch(handleError)
    .then((response) => handleResponse<T>(response, "DELETE", url, data));


const token = Storage.get(Storage.KEYS.TOKEN);



const postFormData = async (
  url: string,
  formData: any,

) => {
  return fetch(url, {
    method: "POST",
    body: formData,
    headers: await {
      Authorization: `Bearer ${token}`,
    },
  })

    .catch((e) => {
      return handleError(e)
    })
    .then((response) => {

      return handleResponse(response, "POST", url, formData)
    });
};

const http = {
  get,
  post,
  put,
  patch,
  destroy,
  postFormData
};

export default http;
