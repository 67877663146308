const ROUTE_PATHS = {
  DASHBOARD: "/dashboard",
  BOOKINGS: "/bookings",
  CENTER: "/center",
  REPORTS: "/reports",
  CRM: "/crm",
  FINANCE: "/finance",
  WHATSNEW: "/whats-new",
  MANUALS: "/manuals",
  ADMINISTRATION: "/administration",
  MOXSEA_USERS: "/moxsea-users",
  FORGOT_PASSWORD: "/forgot-password",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
};

export default ROUTE_PATHS;
