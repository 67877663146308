import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, ScrollArea, Title, Button, Modal, Loader } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import {
  ClubManagementnContextProvider,
  useClubManagementContext,
} from "./ClubManagement.context";
import AddClubs from "../ClubManagement/Components/AddClubs/AddClubs";
import ViewClubs from "./Components/ViewClubs/ViewClubs";
import { getWriteClubPermissions } from "shared/utils/UserPermission";
import ViewUser from "./Components/ViewUser/ViewUser";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";

const ClubManagementContainer = () => {
  const {
    userData,
    loading,
    fetchClubDetails,
    fetchUserDetails,
    userDetailsData,
  } = useClubManagementContext();
  const navigate = useNavigate();
  const [clubManager, setClubManager] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [
    clubDetailsOpened,
    { open: clubDetailsOpen, close: clubDetailsclose },
  ] = useDisclosure(false);
  const { userPermissions } = useUserContext();

  const [
    userDetailsOpened,
    { open: userDetailsOpen, close: userDetailsClose },
  ] = useDisclosure(false);

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "Id",
      //   enableEditing: false,
      //   size: 80,
      // },
      {
        accessorKey: "clubName",
        header: "Club Name",
        size: 150,
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "clubManager",
        size: 150,
        header: "Club Manager",
        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              const clubManagerId = row.original.clubManagerId;
              fetchUserDetails(clubManagerId);
              userDetailsOpen();
            }}
          >
            {" "}
            {row.original.clubManager}
          </div>
        ),
      },
      {
        accessorKey: "activeMemberships",
        size: 200,
        header: "Memberships/Clients",
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "annualRunRate",
        size: 200,
        header: "Annual Run Rate",
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "averageBookings",
        size: 200,
        header: "Average Bookings",
        mantineTableBodyCellProps: {
          align: "center",
        },

        Cell: ({ row }) => (
          <div>{Number(row.original?.averageBookings).toFixed(2)}</div>
        ),
      },
      {
        accessorKey: "Details",
        size: 200,
        header: "Details",
        mantineTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <Button
            onClick={() => {
              const clubId = row.original.id;

              if (row.original.clubManager === null) {
                navigate("/administration/user-management");
              } else {
                const clubManager = row.original.clubManager;
                setClubManager(clubManager);
                fetchClubDetails(clubId);
                clubDetailsOpen();
              }
            }}
          >
            {row.original.clubManager === null ? "Add ClubManager" : "View"}
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: userData,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: false,
    enableTopToolbar: true,
    getRowId: (row) => row.id,
  });

  const AddNewButtonDisable = getWriteClubPermissions(
    "Club Management",
    userPermissions
  );
  return (
    <Flex direction="column">
      <ScrollArea>
        {loading ? (
          <Flex justify="center" align="center" h="70vh">
            <Loader size="xl" />
          </Flex>
        ) : (
          <>
            <Flex mb={8}>
              <Title order={3}>Administration - Club Management</Title>
              <Button
                leftSection={<IconPlus size={14} />}
                variant="default"
                ml={8}
                disabled={!AddNewButtonDisable}
                onClick={open}
              >
                Add New
              </Button>
            </Flex>
            <Modal
              opened={opened}
              onClose={close}
              title={
                <span style={{ fontWeight: "bold" }}>Add Club Details</span>
              }
              size="xl"
              overlayProps={{ backgroundOpacity: 0.55, blur: 3 }}
              closeOnClickOutside={false}
              closeOnEscape={false}
            >
              <AddClubs close={close} />
            </Modal>
            <MantineReactTable table={table} />
            <>
              <Modal
                opened={clubDetailsOpened}
                onClose={clubDetailsclose}
                title={<span style={{ fontWeight: "bold" }}>Club Details</span>}
                size="xl"
                overlayProps={{ backgroundOpacity: 0.55, blur: 3 }}
                closeOnClickOutside={false}
                closeOnEscape={false}
              >
                <ViewClubs
                  closeHandler={clubDetailsclose}
                  clubManager={clubManager}
                />
              </Modal>
            </>

            <>
              <Modal
                opened={userDetailsOpened}
                onClose={userDetailsClose}
                title={
                  <span style={{ fontWeight: "bold" }}>View User Details</span>
                }
                size="lg"
                overlayProps={{ backgroundOpacity: 0.55, blur: 3 }}
                closeOnClickOutside={false}
                closeOnEscape={false}
              >
                <ViewUser
                  userDetailsData={userDetailsData}
                  userDetailsClose={userDetailsClose}
                />
              </Modal>
            </>
          </>
        )}
      </ScrollArea>
    </Flex>
  );
};

const ClubManagement = () => {
  return (
    <ClubManagementnContextProvider>
      <ClubManagementContainer />
    </ClubManagementnContextProvider>
  );
};

export default ClubManagement;
