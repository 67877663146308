import AuthGuard from "../../shared/RouteGuards/AuthGuard";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import Error404 from "./views/sessions/Error";
import BookingsRoutes from "Components/Templates/Bookings/Bookings.routes";
import DashboardRoutes from "Components/Templates/Dashboard/Dashboard.routes";
import CenterRoutes from "Components/Templates/Center/Center.routes";
import ReportsRoutes from "Components/Templates/Reports/Reports.routes";
import CRMRoutes from "Components/Templates/CRM/CRM.routes";
import FinanceRoutes from "Components/Templates/Finance/Finance.routes";

import ManualsRoutes from "Components/Templates/Manuals/Manuals.routes";
import AdministrationRoutes from "Components/Templates/Administration/Administration.routes";
import MoxseaUsersRoutes from "Components/Templates/MoxseaUsers/MoxseaUsers.routes";
import WhatsNewRoutes from "Components/Templates/WhatsNew/WhatsNew.routes";

const themeRoutes = [
  {
    element: <AuthGuard />,
    children: [
      ...DashboardRoutes,
      ...BookingsRoutes,
      ...CenterRoutes,
      ...ReportsRoutes,
      ...CRMRoutes,
      ...FinanceRoutes,
      ...WhatsNewRoutes,
      ...ManualsRoutes,
      ...AdministrationRoutes,
      ...MoxseaUsersRoutes,
    ],
  },
  ...sessionsRoutes,
  {
    path: "*",
    element: <Error404 />,
  },
];

export default themeRoutes;
